import styled from 'styled-components'

export const LayoutStyled = styled.div`
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
`